import { resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.error.cause.js";
import { mixins } from "@/plugins/mixins";
import { ElMessage } from "element-plus";
import 'element-plus/es/components/message/style/css'

import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "np",
  mixins: [mixins],
  data() {
    return {
      columns: [{
        label: '禁停区编码',
        prop: 'npCode'
      }, {
        label: '站点名称',
        prop: 'npName'
      }, {
        label: '禁停原因',
        prop: 'npReason'
      }, {
        label: '所属区域',
        prop: 'areaName'
      }, {
        label: '停车区',
        customRender: props => {
          return _createVNode(_resolveComponent("el-link"), {
            "type": "primary",
            "underline": false,
            "onClick": () => {
              this.getAddress(props.row);
            }
          }, {
            default: () => [_createTextVNode("\u67E5\u770B\u7981\u505C\u533A"), _createVNode("i", {
              "className": "el-icon-view el-icon--right"
            }, null)]
          });
        }
      }, {
        label: '状态',
        customRender: props => {
          if (this.$hasPermission('np_status')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.npStatus,
              "onUpdate:modelValue": $event => props.row.npStatus = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.changeEnabled(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.npStatus === 0 ? '启用' : '禁用';
          }
        },
        width: '110'
      }, {
        label: '创建时间',
        prop: 'createTime'
      }, {
        label: '修改时间',
        prop: 'updateTime'
      }],
      topButtons: [{
        name: '新增',
        code: 'add',
        type: 'primary',
        click: this.npAddR,
        permissions: ['np_add']
      }],
      linkButtons: [{
        name: '编辑',
        code: 'edit',
        type: 'primary',
        click: this.npEdit,
        permissions: ['np_edit']
      }],
      searchItems: [{
        prop: 'npStatus',
        label: '状态',
        type: 'select',
        items: [{
          name: "启用",
          value: 0
        }, {
          name: "禁用",
          value: 1
        }],
        itemProp: {
          label: 'name',
          value: 'value'
        }
      }, {
        type: 'cascader',
        prop: 'npArea',
        label: '服务区域',
        props: {
          checkStrictly: true,
          emitPath: false,
          value: 'id',
          label: 'name',
          lazy: true,
          lazyLoad: (node, resolve) => {
            const {
              level
            } = node;
            if (level === 0) {
              this.$api.uac.area.list({}).then(res => {
                if (res.code === 200) {
                  resolve(res.data);
                } else {
                  resolve([]);
                }
              });
            } else {
              resolve([]);
            }
          }
        }
      }],
      switchLoading: false,
      isAdd: false,
      AMap: null,
      map: null,
      centerLngLat: [104.07318, 30.662818],
      // 默认当前坐标为成都市坐标
      initPath: [],
      path: [],
      // 当前绘制的多边形经纬度数组
      polygonItem: [],
      // 地图上绘制的所有多边形对象
      polyEditors: [],
      // 所有编辑对象数组
      marker: {},
      markers: []
    };
  },
  methods: {
    npAddR() {
      this.$router.push({
        path: '/npAdd'
      });
    },
    getAddress(row) {
      let that = this;
      this.isAdd = true;
      AMapLoader.load({
        "key": this.$amapKey,
        // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",
        // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": ['AMap.Scale', 'AMap.ToolBar', 'AMap.PolyEditor', 'AMap.MouseTool', 'AMap.Polygon'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        this.map = new AMap.Map("amapContainer", {
          center: this.centerLngLat,
          zoom: 14,
          // mapStyle: "amap://styles/darkblue",
          pitch: 0,
          // 指南针南北方向
          rotation: 0,
          // 指南针东西方向
          // viewMode: '3D',//开启3D视图,默认为关闭
          buildingAnimation: false //楼块出现是否带动画
        });

        that.map.addControl(new AMap.Scale()); // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
        that.map.addControl(new AMap.ToolBar()); //在图面添加鹰眼控件，在地图右下角显示地图的缩略图
        // 默认添加一个标记点
        // 创建一个 Marker 实例：  标记点信息
        let marker = new AMap.Marker({
          position: [row.npLongitude, row.npLatitude],
          // 经纬度
          anchor: 'center',
          offset: new AMap.Pixel(0, 0),
          //创建一个自定义图标实例
          icon: new AMap.Icon({
            size: new AMap.Size(28, 30),
            // 图标尺寸
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
            // 自定义图像的url
            imageSize: new AMap.Size(27, 30) // 根据所设置的大小拉伸或压缩图片
          })
        });
        //只保留一个标记点
        that.markers.push(marker);
        that.map.add(marker);
        if (row.paths) {
          let pt = [];
          row.paths.forEach(v => {
            pt.push([v.lng, v.lat]);
          });
          // this.editRectangle(pt);
          //创建多边形 Polygon 实例
          let polygon = new AMap.Polygon({
            path: pt,
            strokeColor: "#FF33FF",
            strokeWeight: 6,
            strokeOpacity: 0.2,
            fillOpacity: 0.4,
            fillColor: '#1791fc',
            zIndex: 50
          });
          //多边形 Polygon对象添加到 Map
          that.map.add(polygon);
          //将覆盖物调整到合适视野
          that.map.setFitView([polygon]);
        }
      }).catch(e => {
        console.log('高德初始化失败', e);
      });
    },
    npEdit(row) {
      this.$router.push({
        path: '/npAdd',
        query: {
          id: row.id
        }
      });
    },
    changeEnabled(user, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          return resolve(true);
        } else {
          ElMessage.error(res.message);
          return reject(new Error('Error'));
        }
      };
      let parms = {};
      if (user.npStatus) {
        parms = {
          npStatus: 1,
          id: user.id
        };
        this.$api.business.np.changeStatus(JSON.stringify(parms)).then(callBack).finally(() => this.switchLoading = false);
      } else {
        parms = {
          npStatus: 0,
          id: user.id
        };
        this.$api.business.np.changeStatus(JSON.stringify(parms)).then(callBack).finally(() => this.switchLoading = false);
      }
    }
  }
};